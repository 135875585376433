#CheckoutPage {
    min-height: 65vh;
  
    .container {
        padding: 20px 0px 20px 0px;

        .rowTitle{
            margin: 0;

            .title {
                margin-bottom: 20px;
                font-size: 24px;
                color: #390040;
                font-weight: bold;
            }
        }
  
        .rowCard {
            margin: 0 20px 0 10px;
    
            .card {
                padding: 20px 20px 20px 20px;
                border-color: #EE880C;
                border-color: #EE880C;
                box-shadow: 0 0 5px 2px #ee880c58;
                transition: background-color 0.3s ease-in-out;
        
                &:hover {
                    cursor: pointer;
                }
        
                .rowIcon {
                    display: flex;
                    justify-content: center;
        
                    .icon {
                        color: #EE880C;
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }
                }
        
                .title {
                    margin: 0;
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    color: #390040;
                }
            }
    
            .active {
                background-color: #ee880c0b;
                border-color: #ee880c;
                box-shadow: 0 0 10px 4px #ee880c;
            }
        }

        .rowCardDetails{
            margin: 0;
            padding: 40px 0px;

            .card {
                padding: 20px 20px 20px 20px;
                border-color: #EE880C;
                border-color: #EE880C;
                box-shadow: 0 0 5px 2px #ee880c58;

                .rowText{
                    margin: 0;
        
                    .title {
                        margin-bottom: 20px;
                        font-size: 20px;
                        color: #390040;
                        font-weight: bold;
                    }

                    .text{
                        margin: 0;
                        font-size: 16px;
                        color: #390040;
                    }

                    .price{
                        margin-top: 20px;
                        font-size: 24px;
                        color: #390040;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}


@media (max-width:991.98px){
  
}