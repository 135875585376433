#ClientSchedulesPage{
    min-height: 60vh;
    
    .rowTitle {
        font-size: 25px;
        font-weight: bold;
        padding-top: 20px;
        color: #390040;
    }

    .cardClientSchedule{
        margin-bottom: 30px;
        border-radius: 15px;
        box-shadow: 0 0 5px 2px #39004056;
        color: #390040;
        
        .rowClientSchedule{
            margin: 20px 0px 10px 0px;
            .colPartnerSchedule{
                display: flex;
                justify-content: center;
                box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
                .image{
                    width: 140px;
                    height: 140px;
                    object-fit: cover;
                    border-radius: 10%;
                }
            }

            .colServiceSchedule{
                box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
                padding: 0px 20px 0px 20px;
            }
            
            .colEmployeeSchedule{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;            

                .image{
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    border-radius: 10%;
                    margin-bottom: 10px;
                }

                .badge{
                    width: fit-content;
                    height: fit-content;
                }
            }
        }

        .rowPartnerSchedule{
            padding: 20px 10px 10px 10px;
            background-color: #390040;
            border-radius: 15px;
            color: white;
            
            .colPartnerSchedule{
                display: flex;
                justify-content: center;
                box-shadow: 5px 0 5px -5px rgba(238, 136, 12, 0.834);
    
                .image{
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 10%;
                }
            }
        }
    }
}

@media (max-width:991.98px){
    #ClientSchedulesPage{
        min-height: 60vh;
        
        .rowTitle {
            text-align: center;
        }

        .rowSchedule{
            padding: 10px;
        }
    
        .cardClientSchedule{
            margin-bottom: 20px;
            
            .rowClientSchedule{
                margin: 20px 0px 10px 0px;
                .colPartnerSchedule{
                    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
                    .image{
                        width: 80px;
                        height: 80px;
                    }
                }
    
                .colServiceSchedule{
                    box-shadow: none;
                    padding: 0px 20px 0px 20px;
                }
                
                .colEmployeeSchedule{
                    margin-top: 10px;
                }
            }
    
            .rowPartnerSchedule{
                padding: 20px 10px 10px 10px;
                
                .colPartnerSchedule{
                    box-shadow: none;
                    margin-bottom: 20px;
        
                    .image{
                        width: 80px;
                        height: 80px;
                        object-fit: cover;
                        border-radius: 10%;
                    }
                }
            }
        }
    }
}