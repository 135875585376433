#SearchPage{
    padding: 20px 0px 20px 0px;
    background-color: #390040;

    .rowSearchType{
        margin-bottom: 10px;
        .buttonType{
            background-color: #390040;
            border-color: #EE880C;
            width: 100%;
            font-weight: bold;

            &:active{
                background-color: #EE880C;
                border-color: #EE880C;
            }
        }

        .buttonTypeSelected{
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }

    .rowSearch{

        .formLabel {
            width: 100%;
            margin-top: 5px;
            border-color: #EE880C;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }
        
        .buttonSearch{
            width: 100%;
            margin-top: 5px;
            background-color: #EE880C;
            border-color: #EE880C;
            font-weight: bold;
        }

        .icon{
            width: 20px;
            height: 20px;
        }
    }
}

#SectionSearch{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px;

    .titleNoSearch{
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color:#390040
    }

    .botForm{
        width: 100%;
        height: 350px;
    }
}

@media (max-width:991.98px){
    #SearchPage{
        padding: 20px 0px 20px 0px;
        background-color: #390040;
    
        .rowSearchType{
            margin-bottom: 10px;
            .buttonType{
                background-color: #390040;
                border-color: #EE880C;
                width: 100%;
                font-weight: bold;
    
                &:active{
                    background-color: #EE880C;
                    border-color: #EE880C;
                }
            }
    
            .buttonTypeSelected{
                background-color: #EE880C;
                border-color: #EE880C;
            }
        }
    
        .rowSearch{
            .buttonSearch{
                margin-top: 5px;
                width: 100%;
                background-color: #EE880C;
                border-color: #EE880C;
                font-weight: bold;
            }
    
            .icon{
                width: 20px;
                height: 20px;
            }
        }
    }
}