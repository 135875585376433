#SectionSearchPartner {
    margin-top: 50px;
    margin-bottom: 50px;

    .rowPartner {
        box-shadow: 0 0 5px 2px #0000001a;
        border-radius: 15px;
        margin-bottom: 20px;

        .colLogoPartner {
            display: flex;
            justify-content: center;

            .logoPartner {
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }
        }

        .colInfoPartner {
            color: #390040;
            margin-top: 15px;
            margin-bottom: 15px;

            .titleMapPartner{
                margin-top: 5px;
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 7px;

                .icon{
                    width: 20px;
                    height: 20px;
                }
            }

            .rowTextsPartner{
                padding: 15px 15px 15px 15px;

                .servicesList{
                    .titleCatPartner {
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 7px;
        
                        .icon{
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }

            .linkPartner{
                text-decoration: none;
                color: #390040;

                &:hover {
                    color: #EE880C;
                }
            }

            .titlePartner {
                font-size: 22px;
                font-weight: bold;
                margin-bottom: 7px;

                .icon{
                    width: 15px;
                    height: 15px;
                }
            }

            .textPartner {
                margin-bottom: 20px;
                text-align: justify;
                .iconStar {
                    width: 30px;
                    height: 30px;
                }
            }

            .titleDatePartner {
                margin-top: 30px;
                font-size: 18px;
                font-weight: bold;
            }
            
            .textDatePartner {
                font-weight: bold;
            }
            
            .rowDatePartner {
                
                .colTimeOptions {
                    margin-bottom: 10px;
    
                    .buttonTime {
                        width: 100%;
                        font-size: 12px;
                        font-weight: bold;
                        color: #390040;
                        background-color: #D9D9D9;
                        border-color: #D9D9D9;
                        border-radius: 50px;
                    }
                }
            }
        }

        .colInfoSchedules{
            color: #390040;
            margin-top: 15px;
            margin-bottom: 15px;

            .rowSchedules{
                .titleSchedules{
                    font-size: 18px;
                    font-weight: bold;

                    .icon{
                        width: 30px;
                        height: 30px;
                    }
                }

                .dropdownSchedules{
                    width: 100%;
                    border-radius: 20px;
                    height: 45px;
                    background-color: #EE880C;
                    border-color: #EE880C;
                    font-weight: bold;
                }

                .dropdownSchedulesMenu{
                    width: 95%;
                }
            }
        }

        .colNoInfoSchedules{
            padding: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            .textNoInfo{
                text-align: center;
                font-size: 22px;
                font-weight: bold;
                color: #390040;
            }
        }

        .rowProfPartner {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            padding: 20px 0px 0px 0px;
            gap: 15px;

            .titleProfPartner {
                font-size: 18px;
                font-weight: bold;

                .icon{
                    width: 30px;
                    height: 30px;
                }
            }

            .colProfPartner {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: #D9D9D9;
                border-radius: 15px;
                padding: 15px;
                
                .photoProfPartner {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                }
    
                .nameProfPartner {
                    color: #390040;
                    font-weight: bold;
                    text-align: center;
                    margin-top: 15px;
                }
            }
        }
    }
}

.modal{

    .colLogoService{
        display: flex;
        justify-content: center;

        .logoService{
            width: 200px;
            height: 200px;
            border-radius: 10%;
        }
    }

    .title{
        color: #390040;
        font-weight: bold;
    }

    .text{
        font-size: 18px;
        color: #390040;
    }

    .rowButton{
        margin: 25px;
        .colButtonCancel{
            display: flex;
            justify-content: end;
            .buttonCancel{
                width: 200px;
    
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;
            }
        }

        .colButtonConfirm{

            .buttonConfirm{
                width: 200px;
                font-weight: bold;
                background-color: #EE880C;
                border-color: #EE880C;
            }
        }

    }
}

@media (max-width:991.98px){
    .modal{

        .colLogoService{
            .logoService{
                margin-bottom: 20px;
                width: 150px;
                height: 150px;
            }
        }

        .text{
            padding: 0px 20px 0px 20px;
        }
    
        .rowButton{
            margin: 25px;
            .colButtonCancel{
                justify-content: center;
            }
    
            .colButtonConfirm{
                display: flex;
                justify-content: center;

                .buttonConfirm{
                    margin-top: 15px;
                }
            }
    
        }
    }

    #SectionSearchPartner {
        margin-top: 30px;
        margin-bottom: 30px;
    
        .rowPartner {
            box-shadow: 0 0 5px 2px #0000001a;
            border-radius: 15px;
            margin-bottom: 20px;

            .colNoInfoSchedules{
    
                .textNoInfo{
                    font-size: 16px;
                }
            }
    
            .rowProfPartner {
                margin: 0;
            }
        }
    }
}