#DatePaginationPartner{
    margin-top: 15px;
    margin-bottom: 15px;
  
    .container{
      
      .rowNoEmployee{
        
        .text{
          color: #390040;
          font-weight: bold;
          text-align: center;
          margin-top: 15px;
        }
      }
  
      .rowButton{
        width: 100%;
        
        .colButtonBack{
          padding: 0;
        }
        
        .button{
          width: 100%;
          height: 50px;
          background-color: #390040;
          border-color: #390040;
          font-size: 12px;
          
          &:hover{
            background-color: #EE880C;
            border-color: #EE880C;
          }
          
          .iconButton{
            margin: 0;
            width: 15px;
            height: 15px;
          }
        }
  
        .dateHeader{
          margin-bottom: 20px;
          background-color: #390040;
          border-radius: 10px;
          height: 50px;

          .textDate{
            padding: 5px;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            color: white;
          }
        }
  
        .rowTimeOptions {
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
  
          .buttonTime {
            width: 80%;
            color: #390040;
            background-color: #D9D9D9;
            border-color: #D9D9D9;
            border-radius: 50px;
            font-size: 12px;
            font-weight: bold;
  
            &.buttonDisabled {
              background-color: #E0E0E0;
              border-color: #E0E0E0;
              color: #B0B0B0;
              cursor: not-allowed;
            }
  
            &.buttonSelected {
              background-color: #EE880C;
              border-color: #EE880C;
              color: white;
            }
          }
        }
      }
  
      .rowMoreLessButton{
        display: flex;
        justify-content: center;
        margin-top: 15px;
        
        .buttonMoreTime {
          width: 150px;
          color: #390040;
          background-color: #D9D9D9;
          border-color: #D9D9D9;
          border-radius: 50px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
  
  @media (max-width:991.98px){
    #DatePaginationPartner{
      margin-top: 15px;
      margin-bottom: 15px;
    
      .container{
    
        .rowButton{
          display: flex;
          justify-content: center;
          width: 100%;
          margin: 0;
  
          .colButtonBack{
            padding: 0;
          }
          
          .button{
            width: 100%;
            height: 50px;
            background-color: #390040;
            border-color: #390040;
            font-size: 12px;
            
            &:hover{
              background-color: #EE880C;
              border-color: #EE880C;
            }
            
            .iconButton{
              width: 15px;
              height: 15px;
            }
          }
    
          .dateHeader{
            margin-bottom: 20px;
            background-color: #390040;
            border-radius: 10px;
            height: 50px;
            
            .textDate{
              font-size: 11px;
              font-weight: bold;
              text-align: center;
              color: white;
            }
          }
    
          .rowTimeOptions {
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
    
            .buttonTime {
              width: 80%;
              color: #390040;
              background-color: #D9D9D9;
              border-color: #D9D9D9;
              border-radius: 50px;
              font-size: 12px;
              font-weight: bold;
    
              &.buttonDisabled {
                background-color: #E0E0E0;
                border-color: #E0E0E0;
                color: #B0B0B0;
                cursor: not-allowed;
              }
    
              &.buttonSelected {
                background-color: #EE880C;
                border-color: #EE880C;
                color: white;
              }
            }
          }
        }
    
        .rowMoreLessButton{
          display: flex;
          justify-content: center;
          margin-top: 15px;
          
          .buttonMoreTime {
            width: 150px;
            color: #390040;
            background-color: #D9D9D9;
            border-color: #D9D9D9;
            border-radius: 50px;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
}  