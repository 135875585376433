#SectionSearchHome{
    padding: 50px 0px 50px 0px;
    background-color: #390040;

    .rowSearchText{
        padding: 10px 0px 20px 0px;
        .text{
            color: white;
            font-size: 35px;
        }
    }

    .rowSearchType{
        margin-bottom: 25px;
        .buttonType{
            background-color: #390040;
            border-color: #EE880C;
            width: 100%;
            font-weight: bold;

            &:active{
                background-color: #EE880C;
                border-color: #EE880C;
            }
        }

        .buttonTypeSelected{
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }

    .rowSearch{

        .formLabel {
            width: 100%;
            margin-top: 5px;
            border-color: #EE880C;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }

        .buttonSearch{
            width: 100%;
            margin-top: 5px;
            background-color: #EE880C;
            border-color: #EE880C;
            font-weight: bold;
        }

        .icon{
            width: 20px;
            height: 20px;
        }
    }
}

@media (max-width:991.98px){
    #SectionSearchHome{
        padding: 20px 0px 20px 0px;
        background-color: #390040;
    
        .rowSearchText{
            padding: 10px 0px 20px 0px;
            .text{
                color: white;
                font-size: 25px;
            }
        }
    
        .rowSearchType{
            margin-bottom: 5px;
            .buttonType{
                background-color: #390040;
                border-color: #EE880C;
                width: 100%;
                font-weight: bold;
    
                &:active{
                    background-color: #EE880C;
                    border-color: #EE880C;
                }
            }
    
            .buttonTypeSelected{
                background-color: #EE880C;
                border-color: #EE880C;
            }
        }
    
        .rowSearch{
    
            .formLabel {
                width: 100%;
                border-color: #EE880C;
    
                &:focus {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
            }
    
            .buttonSearch{
                margin-top: 10px;
                width: 100%;
                background-color: #EE880C;
                border-color: #EE880C;
                font-weight: bold;
            }
    
            .icon{
                width: 20px;
                height: 20px;
            }
        }
    }
}