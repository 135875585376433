#PartnerPage{
    min-height: 60vh;



    .rowPartner{
        padding: 20px;
        margin:20px 0px 20px 0px;
        box-shadow: 0 0 5px 2px #00000018;
        border-radius: 15px;

        .colLogoPartner {
            display: flex;
            justify-content: center;
            padding: 0px 15px 15px 15px;
    
            .logoPartner {
                width: 230px;
                height: 230px;
                border-radius: 15px;
            }
    
        }

        .colInfoPartner {
            color: #390040;

            .mapsLink{
                text-decoration: none;
                color: #EE880C;
                font-weight: bold;
            }

            .titleMapPartner{
                margin-top: 5px;
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 7px;

                .icon{
                    width: 20px;
                    height: 20px;
                }
            }
            
            .titlePartner {
                font-size: 22px;
                font-weight: bold;
                margin-bottom: 7px;
        
                .icon{
                    width: 15px;
                    height: 15px;
                }
            }
        
            .textPartner {
                width: fit-content;
                margin-bottom: 20px;
                text-align: justify;
                
                .iconStar {
                    width: 30px;
                    height: 30px;
                }
            }

            .textPartnerWhats{
                width: fit-content;
                margin-bottom: 20px;
                text-align: justify;
                color: #EE880C;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }

    .rowWorkingPartner{
        margin: 0;
    }
    
    .rowWorkingHours{
        min-height: 110px;
        padding: 15px 15px 15px 15px;
        margin: 20px 0px 20px 0px;
        background-color: #390040;
        border-radius: 15px;
        color: white;
        box-shadow: 0 0 5px 2px #3900405e;

        .titleIconPartner{
            margin-top: 5px;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;
            color: #EE880C;

            .icon{
                width: 25px;
                height: 25px;
            }
        }

        .colWorkingHoursHeader{
            display: flex;
            justify-content: end;

            .workingButton{
                height: 40px;
                background-color: transparent;
                border-color: transparent;
                color: #EE880C;

                .icon{
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .rowHours{
            margin: 0;

            .colWorkingHours{
                padding: 05px;

            }
        }
    }


    .rowTextsPartner{
        padding: 15px 15px 15px 15px;
        margin: 20px 0px 20px 0px;
        background-color: #390040;
        border-radius: 15px;
        box-shadow: 0 0 5px 2px #3900405e;
        color: white;

        .servicesList{
            .titleIconPartner {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 7px;
                color: #EE880C;

                .icon{
                    width: 30px;
                    height: 30px;
                }
            }

            .titlePartner {
                font-size: 22px;
                font-weight: bold;
                margin-bottom: 7px;
        
                .icon{
                    width: 15px;
                    height: 15px;
                }
            }
        
            .textPartner {
                margin-bottom: 20px;
                text-align: justify;
                .iconStar {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
    
    .rowServicesPartner{
        padding: 15px 15px 15px 15px;
        margin: 20px 0px 20px 0px;
        background-color: #390040;
        border-radius: 15px;
        box-shadow: 0 0 5px 2px #3900405e;
        color: white;

        .titleIconService {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;
            color: #EE880C;

            .icon{
                width: 25px;
                height: 25px;
            }
        }

        .serviceContainer {
            margin-bottom: 10px;
            cursor: pointer;
            background-color: #f1f1f1;
            border-radius: 15px;
            
            .rowInfo{
                margin: 0;
                width: 100%;
            }
            
            .serviceHeader {
                display: flex;
                align-items: center;
                padding: 10px;
                color: #390040;

            }
    
            .serviceBody {
                color: #390040;
                padding: 10px;
                border-radius: 10px;
            }

            .serviceDetails{
                display: flex;
                align-items: center;
            }
        }

        .colService{
            .serviceImage{
                border-radius: 10px;
                width: 50px;
                height: 50px;
            }
        }
    }

    .rowContainerSchedules{
        margin: 0px 0px 20px 0px;

        .rowSchedules{
            margin: 0;
            .titleSchedules{
                font-size: 18px;
                font-weight: bold;
                color: #390040;
    
                .icon{
                    width: 30px;
                    height: 30px;
                }
            }
    
            .dropdownSchedules{
                width: 100%;
                border-radius: 20px;
                height: 45px;
                background-color: #EE880C;
                border-color: #EE880C;
                font-weight: bold;
            }
    
            .dropdownSchedulesMenu{
                width: 95%;
            }
        }

        .rowProfPartner {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            padding: 20px 0px 0px 0px;
            gap: 15px;

            .titleProfPartner {
                font-size: 18px;
                font-weight: bold;

                .icon{
                    width: 30px;
                    height: 30px;
                }
            }

            .colProfPartner {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: #D9D9D9;
                border-radius: 15px;
                padding: 15px;
                
                .photoProfPartner {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                }
    
                .nameProfPartner {
                    color: #390040;
                    font-weight: bold;
                    text-align: center;
                    margin-top: 15px;
                }
            }
        }
    }
}

.modal{

    .colLogoService{
        display: flex;
        justify-content: center;

        .logoService{
            width: 200px;
            height: 200px;
            border-radius: 10%;
        }
    }

    .title{
        color: #390040;
        font-weight: bold;
    }

    .text{
        font-size: 18px;
        color: #390040;
    }

    .rowButton{
        margin: 25px;
        .colButtonCancel{
            display: flex;
            justify-content: end;
            .buttonCancel{
                width: 200px;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;
            }
        }

        .colButtonConfirm{

            .buttonConfirm{
                width: 200px;
                font-weight: bold;
                background-color: #EE880C;
                border-color: #EE880C;
            }
        }

    }
}

@media (max-width:991.98px){
    .modal{
        .rowButton{
            .colButtonCancel{
                display: flex;
                justify-content: center;
            }
    
            .colButtonConfirm{
                display: flex;
                justify-content: center;
                margin-top: 20px;
            }
        }
    }
}