#HomePage{
    min-height: 65vh;
    
    .container{
        .carousel{
            margin-top: 60px;
            margin-bottom: 60px;
            .image{
                border-radius: 20px;
                object-fit: cover;
                width: 100%;
                height: 540px;
            }            
        }

        
        .element {
            width: 300;
            height: 300;
            background:
            linear-gradient(#390040 )top,
            linear-gradient(#390040)bottom;
        }    
        
    }

    .rowCard{
        padding: 65px 10px 65px 10px;
        background-color: #390040;

        .title{
            font-size: 45px;
            font-weight: bold;
            text-align: center;
            color: #EE880C;
            margin-bottom: 20px;
        }

        .subtitle{
            font-size: 18px;
            color: #ffffff;
        }
        
        .card{
            margin: 50px;
            padding: 30px 10px 20px 10px;
            border-color: #ffffff;
            height: 250px;
            border-radius: 0px;
            box-shadow: 0 0 10px 2px #ffffff;

            .rowImage{
                display: flex;
                justify-content: center;
                .image{
                    width: 150px;
                    height: auto;
                }
            }

            .cardBody{
                display: flex;
                justify-content: center;
                align-items: center;

                .text{
                    margin-top: 10px;
                    text-align: center;
                    font-size: 16px;
                    font-weight: bold;
                    color: #390040;
                }
            }
        }

        .button{
            width: 120px;
            height: 40px;
            color: #390040;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }

        
    }

    .rowPay{
        margin-top: 20px;
        margin: 70px 150px 70px 150px;

        .cardPay{
            border-color: transparent;
            box-shadow: 0 0 5px 2px #0000001c;
            
            .colImage{
                display: flex;
                justify-content: center;
    
                .image{
                    width: auto;
                    height: auto;
                    size-adjust: 100px;
                }
            }
            .pay{
                display: flex;
                align-items: center;
                margin: 70px 10px 70px 10px;
                
    
                .title{
                    font-size: 28px;
                    font-weight: bold;
                    color: #390040;
                }
    
                .text{
                    padding: 0px 60px 0px 0px; 
                    text-align: justify;
                    font-size: 16px;
                    color: #390040;
                }
            }
        }
    }

    .rowCust{
        margin-bottom: 50px;
        padding: 65px 10px 75px 10px;
        background-color: #390040;

        .colIcon{
            margin-top: 20px;
            display: flex;
            justify-content: center;

            .icon{
                width: 60px;
                height: 60px;
                color: #66E89B;
            }
        }

        .dashedLine {
            position: relative;
            height: 16px;
            margin: 30px 0;
            background-image: radial-gradient(#ffffff 2px, transparent 2px);
            background-size: 16px 16px;
            background-repeat: repeat-x;

            .specialDot {
                position: absolute;
                top: 0;
                width: 16px;
                height: 16px;
                background-color: #66E89B;
                border-radius: 50%;
            }
            
            .specialDot:nth-child(1) {
                left: 11.3%;
            }
        
            .specialDot:nth-child(2) {
                left: 37%;
            }
        
            .specialDot:nth-child(3) {
                left: 61.8%;
            }
        
            .specialDot:nth-child(4) {
                left: 87.8%;
            }
        }
        
        
        .cust{
            border: #390040;
            border-color: #390040;
            background-color: #390040;
            color: #ffffff;
            max-height: 555px;
        }
        
        .title{
            font-size: 45px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
        }
        

        .cardBody{
            margin: 0px 20px 0px 20px;
            padding: 10px 0px 10px 0px;
            text-align: center;

            .divIcon{
                display: none;
            }

            .cTitle{
                margin-top: 10px;
                font-size: 18px;
                font-weight: bold;
            }
    
            .subtitle{
                margin-top: 30px;
            }

            .subtitle2{
                margin-top: 5px;
            }
        }

    }

    .rowVcard{
        padding: 0px 50px 40px 50px;

        .Vtitle{
            font-size: 55px;
            font-weight: bold;
            text-align: left;
            color: #390040;
        }

        .Vsubtitle{
            margin-top: 10px;
            font-size: 18px;
            max-width: 400px;
        }
        
        .Vcard{
            margin: 0;
            padding: 0px 50px 40px 50px;
            color: #390040;
            border-color: transparent;

            .icon{
                width: 70px;
                height: 70px;
            }

            .NVtitle{
                margin-top: 10px;
                font-size: 18px;
                font-weight: bold;
            }
            
            .NVsubtitle{
                color: black;
                margin-top: 5px;
            }
        }

        .rowButton{
            display: flex;
            justify-content: center;
            .Vbutton{
                width: 300px;
                background-color: #EE880C;
                border-color: #EE880C;
                color: #390040;
                font-weight: bold;
            }
        }

    }
}

@media (max-width:991.98px){
    #HomePage{
        .container{
            .carousel{
                margin-top: 30px;
                margin-bottom: 30px;
                .image{
                    border-radius: 20px;
                    width: 100%;
                    height: auto;
                }            
            }
        }
    
        .rowCard{
            padding: 35px 10px 35px 10px;
            background-color: #390040;
    
            .title{
                font-size: 25px;
                margin-bottom: 0px;
            }
    
            .subtitle{
                font-size: 14px;
                color: #ffffff;
            }
            
            .card{
                height: auto;
    
                .rowImage{
                    .image{
                        width: 100px;
                    }
                }
            }
    
            .button{
                width: 120px;
                height: 40px;
                color: #390040;
                font-weight: bold;
                background-color: #EE880C;
                border-color: #EE880C;
            }            
        }
    
        .rowPay{
            margin: 40px 5px 40px 5px;
    
            .cardPay{
                border-color: transparent;
                box-shadow: 0 0 5px 2px #0000001c;
                
                .colImage{
                    .image{
                        width: 50%;
                        height: auto;
                    }
                }

                .pay{
                    display: flex;
                    align-items: center;
                    margin: 30px 10px 30px 10px;
                    
        
                    .title{
                        margin-top: 10px;
                        font-size: 20px;
                        text-align: center;
                    }
        
                    .text{
                        padding: 0px 0px 0px 0px; 
                        text-align: justify;
                        font-size: 16px;
                    }
                }
            }
        }
    
        .rowCust{
            margin-bottom: 50px;
            padding: 65px 10px 75px 10px;
            background-color: #390040;
    
            .colIcon{
                display: none;
            }
    
            .dashedLine {
                display: none;
            }
            
            
            .cust{
                border: #390040;
                border-color: #390040;
                background-color: #390040;
                color: #ffffff;
                max-height: 555px;
            }
            
            .title{
                font-size: 25px;
                font-weight: bold;
                text-align: center;
                color: #ffffff;
            }
            
    
            .cardBody{

                .divIcon{
                    display: flex;
                    justify-content: center;

                    .icon{
                        width: 60px;
                        height: 60px;
                        color: #66E89B;
                    }
                }
    
                .cTitle{
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: bold;
                }
        
                .subtitle{
                    margin-top: 5px;
                }
    
                .subtitle2{
                    margin-top: 5px;
                }
            }
    
        }
    
        .rowVcard{
            padding: 0px 10px 40px 10px;
    
            .Vtitle{
                font-size: 25px;
                text-align: center;
            }
    
            .Vsubtitle{
                text-align: justify;
                margin-top: 10px;
                font-size: 18px;
                max-width: 110%;
            }
            
            .Vcard{
                margin: 0;
                padding: 20px 50px 20px 50px;
                color: #390040;
                border-color: transparent;
                
                .colIcon{
                    display: flex;
                    justify-content: center;
                }
                
                .icon{
                    width: 70px;
                    height: auto;
                }
    
                .NVtitle{
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: bold;
                }
                
                .NVsubtitle{
                    color: black;
                    margin-top: 5px;
                }
            }
    
            .rowButton{
                .Vbutton{
                    width: 200px;
                    background-color: #EE880C;
                    border-color: #EE880C;
                    color: #390040;
                    font-weight: bold;
                }
            }
    
        }
    }
}