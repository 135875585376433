#SectionLogin {
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .containerLogin {
        max-width: 1100px;
    }
  
    .rowLogo {
        justify-content: center;
  
        .logoLogin {
            max-width: 400px;
            height: auto;
        }
    }
  
    .rowTitle {
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        padding-top: 20px;
    }
  
    .rowForm {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .formLabel {
            width: 100%;
            border-color: #EE880C;
            border-radius: 15px;
            margin-top: 20px;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }

        .formLabel{
            color: #390040;
            font-weight: bold;
            padding-left: 15px;
        }

        .formControl {
            height: 60px;
            border-color: #EE880C;
            border-radius: 15px 0px 0px 15px;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }

            &:disabled {
                background-color: #ee880c04;
            }
        }

        .formControl2 {
            height: 60px;
            border-color: #EE880C;
            border-radius: 15px;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }

            &:disabled {
                background-color: #ee880c04;
            }
        }

        .showPasswordButton{
            height: 60px;
            background-color: transparent;
            border-color: #EE880C;
            border-radius: 0px 15px 15px 0px;
            color: #EE880C;
        }

        .formCheckbox{
            margin-top: 15px;
            color: #390040;

            &:checked{
                background-color: #390040;
            }
        }

        .rowRecaptcha{
            justify-content: center;
            margin-top: 15px;

            .recaptcha{
                width: auto;
            }
        }
  
        .button {
            width: 100%;
            height: 50px;
            border-radius: 10px;
            border-color: #390040;
            background-color: #390040;
            margin-top: 20px;
        }
    }

    .rowTerms{
        margin-top: 20px;

        .termsText{
            text-align: center;
            color: #390040;
            
            .link{
                color: #EE880C;
                text-decoration: none;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }

    .rowSignUp{
        margin-top: 10px;
        
        .signUpText{
            text-align: center;
            color: #390040;
        }
    }
}
