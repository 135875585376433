#CheckoutPage{

  .checkoutContainer {

    .rowCheckout{
      margin: 0;
    }
  
    .card {
      padding: 20px 20px 20px 20px;
      margin: 0px 0px 20px 0px;
      border-color: #EE880C;
      box-shadow: 0 0 5px 2px #ee880c58;
  
      .cardTitle{
        font-size: 20px;
        color: #390040;
        font-weight: bold;
      }

      .formLabel{
        color: #390040;
        font-weight: bold;
        padding-top: 20px;
      }

      .divFormControl {
        height: 38px;
        border-color: #EE880C;
  
        &:focus {
          box-shadow: 0 0 5px 2px #EE880C;
          border-color: #EE880C;
        }
  
        &:disabled {
          background-color: #ee880c04;
        }
      }
  
      .formControl {
        border-color: #EE880C;
  
        &:focus {
          box-shadow: 0 0 5px 2px #EE880C;
          border-color: #EE880C;
        }
  
        &:disabled {
          background-color: #ee880c04;
        }
      }

      .qrCodeImage{
        width: 300px;
        height: 300px;
      }
    }
  
    .rowButton{
      display: flex;
      justify-content: center;
      margin: 0;
  
      .button {
        width: 250px;
        border-radius: 5px;
        border-color: #390040;
        background-color: #390040;
        margin-top: 20px;
        font-weight: bold;
      }
    }
  }
    
}